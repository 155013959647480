<template>
  <div>
    <a-modal class="modal-box" :title="title" :visible="visible" @ok="handleOk" @cancel="cancel" :width="width">
      <div>
        <div v-for="item in fromList" :key="item.id" style="width:100%;">
          <!-- 单选 -->
          <div v-if="item.type === 'select'">
            <div class="m-t1 m-b1"><span style="color: red;">*</span>{{ item.label }}<span class="m-l1" style="color: red;">{{ item.tips }}</span></div>
            <div :style="{ width: item.width ? item.width : '50%' }">
              <a-select style="width: 100%" :disabled="item.disabled" v-model:value="fromOptions[item.prop]" @select="advocateSelect" :placeholder="item.placeholder">
                <a-select-option v-for="opt in item.opt" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
              </a-select>
            </div>
          </div>
          <!-- 搜索框 -->
          <div v-if="item.type === 'selectSearch'&&fromOptions.client==2">
            <div class="m-t1 m-b1"><span style="color: red;">*</span>{{ item.label }}<span class="m-l1" style="color: red;">{{ item.tips }}</span></div>
            <div :style="{ width: item.width ? item.width : '50%' }">
              <a-select style="width: 100%" :disabled="item.disabled" @select="select" v-model:value="fromOptions[item.prop]" show-search :placeholder="item.placeholder" :not-found-content="null" @search="search">
                <a-select-option v-for="opt in item.opt" :key="opt.value" :title="opt.value" :value="opt.label">{{ opt.label }}</a-select-option>
              </a-select>
            </div>
          </div>
          <!-- 搜索框多选 -->
          <div v-if="item.type === 'selectSearchMore'&&fromOptions.client">
            <div class="m-t1 m-b1"><span style="color: red;">*</span>{{fromOptions.client==2? item.label:item.label2 }}<span v-if="fromOptions.client==1" class="m-l1" style="color: red;">{{ item.tips }}</span></div>
            <div :style="{ width: item.width ? item.width : '50%' }">
              <a-select style="width: 100%" v-model:value="item.cleOpt" @change="selectMore" :placeholder="fromOptions.client==2?item.placeholder:item.placeholder2" mode="multiple" :not-found-content="null" @search="searchMore">
                <a-select-option v-for="opt in item.opt" :key="opt.value" :title="opt.value" :value="opt.label">{{ opt.label }}</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div style="height: 20px;" v-if="!fromOptions.client">

        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import { fileUpload as upload } from '@/api/marketing/modList'
import { message } from "ant-design-vue";
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "title",
  },
  fromList: {
    type: Array,
    default: [],
  },
  width: {
    type: String,
    default: "40%",
  },
});

const emit = defineEmits([
  "cancel",
  "getVal",
  "seleChange1",
  "search",
  "select",
  "searchMore",
  "selectMore",
  "advocateSelect"
]);
const cancel = () => {
  emit("cancel");
};

const handleOk = () => {
  emit("getVal", fromOptions.value);
};
//验证规则数据处理fromOptions
const fromOptions = ref({});
const setValue = () => {
  let option = {};
  props.fromList.map((item) => {
    if (item.prop1) {
      option[item.prop1] = item.value1;
    }
    if (item.prop2) {
      option[item.prop2] = item.value2;
    } else {
      option[item.prop] = item.value;
    }
  });
  fromOptions.value = option;
};
const advocateSelect = (item) => {
  if (item == 1) {
    fromOptions.value.department = null
    props.fromList[1].opt = []
  }
  props.fromList[2].opt = []
  props.fromList[2].cleOpt = []
  emit('advocateSelect', item)
}
// 选择的值
const select = (val, i) => {
  emit("select", i);
};
//多选的值
const selectMore = (val, i) => {
  emit("selectMore", i);
};
//搜索框单选
const search = (val) => {
  emit("search", val);
};
//搜索框多选
const searchMore = (val) => {
  emit("searchMore", val);
};

watch(
  () => props.fromList.map((item) => item.value),
  () => {
    setValue();
  },
  {
    immediate: true,
  }
);
watch(
  () => props.visible,
  () => {
    setValue();
  }
);
onMounted(() => {
  setValue();
});
</script>

<style lang="less" scoped>
.chengeStyle {
  :deep(.ant-form-item-label) {
    width: 100%;
    text-align: left;
  }
  :deep(.ant-form-item) {
    margin-bottom: 5px;
  }
}
.tips {
  :deep(.ant-form-item-required:after) {
    content: '（输入关键词按 “ENTER” 确认后继续输入，每个关键词在5字以内）';
    color: #6b778c;
    font-size: 12px;
  }
}

.tagBox {
  width: 100%;
  min-height: 35px;
  border: 1px solid #d9d9d9;
  padding: 5px 0;
  flex-wrap: wrap;
  .tag {
    background: #f2f2f2;
    padding: 2px 8px;
    margin-left: 5px;
    height: 25px;
    margin-bottom: 5px;
  }
  :deep(.ant-input) {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.textarea {
  resize: none;
}
</style>
