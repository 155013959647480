<template>
  <!--  关联列表-->
  <div>
    <div>
      <repPbcNav :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></repPbcNav>
    </div>
    <div>
      <repForm @confirm="confirm" @edit="edit" :pagination="pagination" @changePage="changePage" btnTitle="关联知识" @handleAdd="handleAdd" v-model:columns="columns" :reload="contentRelatePage" :dataList="dataList" @swtichBtn="swtichBtn" :searchLoading="searchLoading"></repForm>
    </div>
    <div>
      <articleModal @advocateSelect="advocateSelect" @getVal="getVal" @selectMore="selectMore" @searchMore="searchMore" @select="select" :visible="visible" @search="search" @cancel="cancel" title="关联知识" :fromList="fromList"></articleModal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, createVNode } from "vue";
import repPbcNav from "../components/repPbcNav.vue";
import repForm from "../components/repForm.vue";
import articleModal from "./articleModal.vue";
import { debounce } from "@/utils/util.js";
import {
  contentRelatePage as page,
  relevanceList,
  relateAdd,
  relateEdit,
  findRelateId,
  relateDelete,
  oaTree
} from "@/api/repository/repository";
import { getOrgTreeList } from "@/api/global";
import { message } from "ant-design-vue";

const searchLoading = ref(false);
const isEdit = ref(false);//判读是否为编辑事件
const visible = ref(false);
const pageIdx = ref(1);
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showTotal: (totals) => `共 ${totals} 条`
});
const iptData = ref([
  {
    type: "select",
    placeholder: "适用部门",
    value: null,
    prop: "name",
    width: 200,
    opt: [],
  },
  {
    type: "cascader",
    placeholder: "选择类目",
    value: null,
    prop: "category",
    width: 200,
    opt: [],
  },
  {
    type: "input",
    placeholder: "主知识标题",
    value: "",
    prop: "keyWords",
    width: 200,
  } 
])
const columns = ref([
  {
    title: "关联主知识",
    dataIndex: "mainContentTitle",
    key: "mainContentTitle",
    align: "center",
  },
  {
    title: "关联副知识",
    dataIndex: "subContentTitle",
    key: "subContentTitle",
    customHeaderCell: () => ({ style: { textAlign: "center" } }),
  },
  {
    title: "关联类型",
    dataIndex: "relateType.label",
    key: "relateType.label",
    align: "center",
  },
  {
    title: "关联人",
    key: "operator",
    dataIndex: "operator",
    align: "center",
  },
  {
    title: "关联时间",
    key: "createTime",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    key: "edit",
    dataIndex: "edit",
    slots: { customRender: "edit" },
    align: "center",
  },
]);
const dataList = ref([]);//表格数据
const fromList = ref([
  {
    label: "选择关联类型",
    name: "client",
    type: "select",
    placeholder: "请选择关联类型",
    value: null,
    prop: "client",
    width: "33%",
    disabled: false,
    opt: [
      {
        id: 1,
        name: "相似知识（平级）",
      },
      {
        id: 2,
        name: "推荐知识（下级）",
      },
    ],
   
  },
  {
    label: "关联主知识题",
    name: "department",
    type: "selectSearch",
    placeholder: "搜索选择主知识标题",
    value: null,
    prop: "department",
    width: "100%",
    disabled: false,
    opt: [],
  },
  {
    label: "关联副知识标题",
    label2: "关联知识标题",
    name: "title",
    type: "selectSearchMore",
    placeholder: "搜索选择副知识标题",
    placeholder2: "搜索选择知识标题",
    value: null,
    prop: "title",
    width: "100%",
    cleOpt: [],
    opt: [],
    tips:'(至少选择两个)'
  },
]);
//控制分页
const changePage = (val) => {
  pageIdx.value = val.current;
  contentRelatePage(recordSearch.value);
  pagination.value = val;
};
//搜索提交
const recordSearch = ref({})
const searchAdd = (val) => {
  let msg = {
    applyOrgId:val.name,
    knowledgeTypeId:val.category&&val.category.length==2?val.category[1]:'',
    title:val.keyWords
  }
  recordSearch.value = msg
  pageIdx.value = 1
  pagination.value.current = 1
  contentRelatePage(msg)
};
//重置
const resetBtn = () => {
  pageIdx.value = 1
  pagination.value.current = 1
  contentRelatePage()
  recordSearch.value = {}
}
//编辑
const editID = ref(""); //编辑id
const editData = ref([]);//编辑的原始数据
const edit = (val) => {
  findRelateId(val.id).then((res) => {
    let cleOpt = [];
    res.data.subContentTitle.forEach((item) => {
      cleOpt.push(item.title);
    });
    editData.value = res.data;
    fromList.value[0].value = res.data.relateType.value;
    fromList.value[0].disabled  = true
    fromList.value[1].value = res.data.mainContentTitle;
    fromList.value[1].disabled = true
    fromList.value[2].cleOpt = cleOpt;
    fromList.value[2].opt = []
    editID.value = val.id;
    isEdit.value = true;
    visible.value = true;
    state.assistantRelation = []
    assisNum.value = 1
  });
};
//解除关联
const confirm = (item) => {
  relateDelete(item.id).then((res) => {
    if (res.code == 10000) {
      message.success(res.msg);
      if(dataList.value.length == 1&&pageIdx.value!==1){
        pageIdx.value--
      }
      contentRelatePage();
    }
  });
};
//添加
const handleAdd = () => {
  fromList.value[0].value = null;
  fromList.value[1].value = null;
  fromList.value[1].opt = [];
  fromList.value[2].opt = [];
  fromList.value[2].cleOpt = [];
  visible.value = true;
  isEdit.value = false;
  fromList.value[1].disabled = false
  fromList.value[0].disabled = false
};

//是否隐藏
const swtichBtn = (val) => {
  val.isShow = !val.isShow;
};

// 关联列表请求
const reqRelevance = (val) => {
  if (!state.content) return;
  relevanceList({
    name: val ? val : state.content,
  }).then((res) => {
    const list = ref([]);
    res.data.forEach((item) => {
      list.value.push({
        label: item.title+`【${item.applyOrg}】`,
        value: item.contentId,
      });
    });
    if (state.isMain) {
      fromList.value[1].opt = list.value;
    } else {
      fromList.value[2].opt = list.value;
    }
  });
};
const state = reactive({
  timer: null,
  content: "",
  isMain: true, //true为主知识,反之
  mainRelation: {}, //主知识
  assistantRelation: [], //副知识
});
//关联类型选择的值
const select = (val) => {
  state.mainRelation = val;
};
//副知识关联的值
const assisNum = ref(1)
const selectMore = (val) => {
  state.assistantRelation = val;
  assisNum.value = val.length;
};
//编辑确定
const editSubmit = (val) => {
  let data = editData.value;
  let form = fromList.value;
  if(assisNum.value == 0&&val.client==2){
    message.warn("请输入选择副知识标题")
    return
  }
  if(assisNum.value == 0&&val.client==1){
    message.warn("请输入选择关联知识标题")
    return
  }
  // 副知识数据处理
  let newMsg = []
  if (state.assistantRelation.length == 0) { //表示用户没有改变
    data.subContentTitle.forEach((opt) => {
      newMsg.push({
        title: opt.contentId,
        value: opt.title,
      })
    })
    state.assistantRelation = newMsg
  } else {
    data.subContentTitle.forEach((opt) => { //用户重新编辑
      form[2].cleOpt.forEach(item => {
        if (opt.title == item) {
          newMsg.push({
            title: opt.contentId,
            value: opt.title,
          })
        }
      })
    })
    state.assistantRelation = state.assistantRelation.concat(newMsg)
  }
  let subContentTitle = [];
  const map = new Map()
    const newArr = state.assistantRelation.filter(v => !map.has(v.title) && map.set(v.title, v))
    newArr.forEach((item) => {
    if (item.value) {
      subContentTitle.push({
        contentId: item.title,
        title: item.value,
      });
    }
  });
  let msg = {
    subContentTitle: subContentTitle, //副知识
  };
  if (formRulesEdit(msg,val.client) !== true) {
    message.warning(formRulesEdit(msg,val.client));
    return;
  }
  reqRelateEdit(msg)
};
//获取关联数据确定按钮
const getVal = (val) => {
  if (isEdit.value) {
    editSubmit(val);
    return;
  }
  let subContentTitle = [];
  state.assistantRelation.forEach((item) => {
    subContentTitle.push({
      contentId: item.title,
      title: item.value,
    });
  });
  let relateType = {};
  fromList.value[0].opt.forEach((item) => {
    if (item.id == val.client) {
      relateType = {
        label: item.name,
        value: item.id,
      };
    }
  });
  let msg = {
    contentId: state.mainRelation.title?state.mainRelation.title:'', //主知识
    mainContentTitle: state.mainRelation.value? state.mainRelation.value:'',
    relateType: relateType, //关联类型
    subContentTitle: subContentTitle, //副知识
  };
  if (formRules(msg,val.client) !== true) {
    message.warning(formRules(msg,val.client));
    return;
  }
  reqRelateAdd(msg);
};
const cancel = () => {
  visible.value = false;
};
//根据关联类型判断
const advocateSelect = (item) => {
  if(item == 1){
    state.mainRelation = {}
  }
  state.assistantRelation = [];
  assisNum.value = 1;
}
//增加新关联请求
const reqRelateAdd = (msg) => {
  relateAdd(msg).then((res) => {
    if (res.code == 10000) {
      message.success(res.msg);
      state.mainRelation = {}, //主知识
      state.assistantRelation = [],
      cancel();
      contentRelatePage();
    }
  });
};
//编辑关联请求
const reqRelateEdit = (msg) => {
  msg.id = editID.value
  relateEdit(msg).then((res) => {
    if (res.code == 10000) {
      message.success(res.msg);
      cancel();
      state.assistantRelation = []
      assisNum.value = 1
      contentRelatePage();
    }
  });
}
//判断是否表单填写完整添加
const formRules = (msg,id) => {
  if(!msg.relateType.label){
    return "请选择关联类型";
  } else if (!msg.mainContentTitle&&id==2) {
    return "请输入选择主知识标题";
  }  else if (msg.subContentTitle.length == 0&&id==2) {
    return "请输入选择副知识标题";
  }else if(msg.subContentTitle.length == 0&&id==1){
    return "请输入选择关联知识标题";
  } else if(id==1&&msg.subContentTitle.length<2){
    return "至少选择两个关联知识标题";
  }  else {
   return true;
  }
};
//判断是否表单填写完整编辑
const formRulesEdit = (msg,id) => {
  if (msg.subContentTitle.length==0&&id==2) {
    return "请输入选择副知识标题";
  }else if (msg.subContentTitle.length==0&&id==1){
    return "请输入选择关联知识标题";
  }else if(msg.subContentTitle.length<2&&id==1){
    return "至少选择两个关联知识标题";
  }  else {
    return true;
  }
};
// 多选
const searchMore = (val) => {
  state.content = val;
  state.isMain = false;
  debounce(state, reqRelevance, 800);
};
//输入框关联词单选
const search = (val) => {
  state.content = val;
  state.isMain = true;
  debounce(state, reqRelevance, 800);
};
//获取关联分页列表
const contentRelatePage = (msg={}) => {
  searchLoading.value = true;
  let primary = {
    orders: [
      {
        asc: false,
        column: "",
      },
    ],
    current: pageIdx.value,
    size: 10,
  }
  Object.assign(primary,msg)
  page(primary).then((res) => {
    res.data.records.forEach((item) => {
      item.edit1 = "编辑";
      item.edit2 = "解除关联";
      item.edit2Tips = "您确定要解除关联吗？";
    });
    dataList.value = res.data.records;
    pagination.value.total = res.data.total;
    searchLoading.value = false;
  });
};
//获取部门
const getDepartment = () => {
  getOrgTreeList({}).then((res) => {
    if (res.code !== 10000) return;
    let treeList = res.data[0].treeList;
    iptData.value[0].opt = treeList;
  });
};
// 获取类目列表
const resData = ref([]);
const grtOaList = () => {
  oaTree().then((res) => {
    if (res.code !== 10000) return;
    iptData.value[1].opt = res.data
    resData.value = res.data;
  });
}
onMounted(() => {
  contentRelatePage();
  getDepartment()
  grtOaList()
});
</script>

<style lang="less" scoped></style>
